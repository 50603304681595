import {
    MYACCOUNT_DASHBOARD,
    MYACCOUNT_INBOX,
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_CALENDAR,
    MYACCOUNT_PROFILE,
    FRONTEND_SITE_URL,
    WEBSITE_URL,
    MYACCOUNT_LIGHT,
} from "gatsby-theme-myaccount/src/core/constants/urls";

import { createSearchUrl } from "gatsby-theme-myaccount/src/core/components/MyAccount/pages/properties/functions"
import CustomLogoIcon from './src/assets/icons/linleyandsimpson/logo.inline.svg';
import Logo from './src/assets/icons/linleyandsimpson/logo.svg';
import LogoSecondary from './src/assets/icons/linleyandsimpson/logo-secondary.svg';

const companyName = 'Linley & Simpson';
const copyrightAltText = '';
const themeName = 'linleyandsimpson';
const staticFolderName = 'common';
const defaultArea = 'yorkshire';
const defaultCountry = 'GB';
// const telephone = '+44 20 44334 8877';
const mailto = 'enquiries@linleyandsimpson.co.uk';
const termsSiteLink = ``;
const privacySiteLink = `${FRONTEND_SITE_URL}/privacy-policy`;
const fixfloPluginUrl = "https://linleyandsimpsonwithlarards.fixflo.com/issue/plugin";
const fixfloUrl = "https://linleyandsimpsonwithlarards.fixflo.com/Auth/Login";

const facebook = "https://www.facebook.com/linleyandsimpson"
const instagram = "https://www.instagram.com/linleyandsimpson/";
const linkedIn = "https://www.linkedin.com/company/linley-and-simpson";

// common variable
// color
const primaryColor='#031D2E', secondayColor='#43C7EE', bodyPrimaryColor= '#031D2E', bodySecondaryColor='#4C5053';
const tabColor = bodySecondaryColor;
// font Family
const primaryFontFamily = "'Open Sans', sans-serif", secondaryFontFamily = "'Open Sans', sans-serif";

// Images
// Static modules
const tileForSaleImg = `/images/${staticFolderName}/find-a-property-for-sale.jpg`;
const tileToRentImg = `/images/${staticFolderName}/find-a-property-to-rent.jpg`;
const tileTeamImg = `/images/${staticFolderName}/meet-the-team.jpg`;
const tileMortgageImg = `/images/${staticFolderName}/need-mortgage.jpg`;
const tileForSaleLink = `${WEBSITE_URL}/property/for-sale/in-yorkshire/`;
const tileToRentLink = `${WEBSITE_URL}/property/to-rent/in-yorkshire/`;
const tileTeamLink = `${WEBSITE_URL}/contact/branch-finder/`;
const tileMortgageLink = `${WEBSITE_URL}/financial-services/`;
const loginBg = `https://ggfx-linleyandsimpson.s3.eu-west-2.amazonaws.com/i.prod/login_bg_0070bc72ea.jpg`;

// Heading definitions h1 - h6 + line heights
const h1Size = "2.25rem", // 1rem === htmlFontSize
      h1LineHSize = 1.09, // lineHeight / fontSize
      h2Size = "3.125rem",
      h2LineHSize = 1.28,
      h3Size = "2.25rem",
      h3LineHSize = 1.14,
      h4Size = "2rem",
      h4LineHSize = 1.25,
      h5Size = "1.125rem",
      h5LineHSize = 1.33,
      h6Size = "1rem",
      h6LineHSize = 1.4;

// body size custom definitions
const bodyXSmallSize = "0.6875rem",
      lineHeightBXSmallSize = 1.63,
      bodySmallSize = "0.875rem",
      lineHeightBSmallSize = 1.43,
      bodyMediumSize = "1rem",
      lineHeightBMediumSize = 1.5,
      bodyLargeSize = "1.125rem",
      lineHeightBLargeSize = 1.44,
      bodyXLargeSize = "1.5rem",
      lineHeightBXLargeSize = 1.55;

// Custom grid column/container values
const gridItemPmobileValue = "1rem",
      gridItemPtabletValue = "2.5rem",
      gridItemPdesktopValue = "1.875rem";
    //   gridItemPdesktopValue = "3.75rem";

const shadowKeyUmbraOpacity = 0.1;
const shadowKeyPenumbraOpacity = 0.1;
const shadowAmbientShadowOpacity = 0.1;
function createShadow(...px) {
    return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
    ].join(',');
}

const propertyLink  = (p) => {
    let department = {
        residential: 'property',
        commercial: 'commercial-property',
        new_developments: 'new-homes'
    }
    let sectorPath = p.sector || p.property_type || p.search_type;
    let departmentPath = p.department ? department[p.department] : department['residential'];
    if (sectorPath) {
        sectorPath = sectorPath.replace(' ', '-');
        sectorPath = sectorPath === 'to-let' ? 'to-rent' : sectorPath
    }
    return `${FRONTEND_SITE_URL}/${departmentPath}-${sectorPath}/${p.slug}-${p.objectID || p.id}`
}

const resultLinkFun = (params) => {
    let settings = {
        useStatus:true,
        basePath: {
            residential: 'property',
            commercial: 'commercial',
            new_developments: 'new-homes'
        },
        defaultArea: defaultArea,
        // statusDelimiter: '-',
        minPricePrefix: 'above', maxPricePrefix: 'below'
    }
    return `${FRONTEND_SITE_URL}${createSearchUrl(params, settings)}`;
}

export default {
    // Redefine the custom heading values so we have them available from the {theme} object
    h1: h1Size,
    h1LineH: h1LineHSize,
    h2: h2Size,
    h2LineH: h2LineHSize,
    h3: h3Size,
    h3LineH: h3LineHSize,
    h4: h4Size,
    h4LineH: h4LineHSize,
    h5: h5Size,
    h5LineH: h5LineHSize,
    h6: h6Size,
    h6LineH: h6LineHSize,

    // Redefine the custom body values so we have them available from the {theme} object
    bodyXSmall: bodyXSmallSize,
    lineHeightBXSmall: lineHeightBXSmallSize,
    bodySmall: bodySmallSize,
    lineHeightBSmall: lineHeightBSmallSize,
    bodyMedium: bodyMediumSize,
    lineHeightBMedium: lineHeightBMediumSize,
    bodyLarge: bodyLargeSize,
    lineHeightBLarge: lineHeightBLargeSize,
    bodyXLarge: bodyXLargeSize,
    lineHeightBXLarge: lineHeightBXLargeSize,

    // Redefine custom grid column/container values so we have them available from the {theme} object
    gridItemPmobile: `${gridItemPmobileValue}`,
    gridItemPtablet: `${gridItemPtabletValue}`,
    gridItemPdesktop: `${gridItemPdesktopValue}`,
    tabSecondLevel: h6Size,

    /**
     * MUI GLOBAL OVERRIDES OF STYLES
     */
    mixins: (theme) => ({
        toolbar: {
            minHeight: 60,
            [theme.breakpoints.up('md')]: {
                minHeight: 96
            },
        },
    }),
    palette: {
        primary: {
            // light: '#757ce8',
            main: primaryColor,
            dark: secondayColor,
            heading: primaryColor,
            icon: primaryColor,
            contrastText: '#fff',
        },
        secondary: {
            // light: '#ff7961',
            main: secondayColor,
            // dark: '#ba000d',
            contrastText: '#fff',
        },
        accent: {
            main: bodyPrimaryColor,
        },
        divider: "#D8D8D8",
        background: {
            paper: "#fff",
            default: "#fff",
        },
        grey: {
            // custom grey values
            grey1: bodyPrimaryColor,
            grey2: bodySecondaryColor,
            grey3: "#DDDDDD",
            grey4: "#F6F6F5",
            grey5: "#FAFAFA",
            grey6: "#BDBDBD",
        },
        text: {
            primary: bodyPrimaryColor,
        },
        icon: {
            loginLogo: bodyPrimaryColor
        },
        tabs: {
            primary: tabColor,
            selected: secondayColor
        }
    },
    shadows: [
        'none',
        createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
        createShadow(0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0), // elevation={2} is custom changed for module bgs
        createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
        createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
        createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
        createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
        createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
        createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
        createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
        createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
        createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
        createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
        createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
        createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
        createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
        createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
        createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
        createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
        createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
        createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
        createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
        createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
        createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
        createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
    ],
    shape: {
        borderRadius: 3,
    },
    spacing: factor => factor !== 'auto' ? `${0.50 * factor}rem` : factor, // this translates to 8px
    components: {
        MuiContainer: { // this <Container> has to be the parent of Mui Grid `container` item element
            styleOverrides: {
                maxWidthXl: {
                    paddingLeft: `${gridItemPmobileValue}`,
                    paddingRight: `${gridItemPmobileValue}`,
                    "@media screen and (min-width: 600px)": {
                        paddingLeft: `${gridItemPtabletValue}`,
                        paddingRight: `${gridItemPtabletValue}`,
                    },
                    "@media screen and (min-width: 1280px)": {
                        paddingLeft: `calc(${gridItemPdesktopValue} * 2)`,
                        paddingRight: `calc(${gridItemPdesktopValue} * 2)`,
                    },
                }
            },
        },
        MuiGrid: {
            styleOverrides: {
                // I've selected the spacing={1} to be a custom theme spacing grid for the use of the project
                // We change the horizontal spacings of items on different breakpoints and Remove vertical spacing
                // In the project each component should ideally be inside the grid item so the spacings are used consistently
                container: {
                    "&.MuiGrid-spacing-xs-1": {
                        width: `calc(100% + (${gridItemPmobileValue} * 2))`,
                        margin: `0 -${gridItemPmobileValue}`,
                        "@media screen and (min-width: 600px)": {
                            width: `calc(100% + (${gridItemPtabletValue} * 2))`,
                            margin: `0 -${gridItemPtabletValue}`,
                        },
                        "@media screen and (min-width: 1280px)": {
                            width: `calc(100% + (${gridItemPdesktopValue} * 2))`,
                            margin: `0 -${gridItemPdesktopValue}`,
                        },
                        "> .MuiGrid-item": {
                            padding: `0 ${gridItemPmobileValue}`,
                            "@media screen and (min-width: 600px)": {
                                padding: `0 ${gridItemPtabletValue}`,
                            },
                            "@media screen and (min-width: 1280px)": {
                                padding: `0 ${gridItemPdesktopValue}`,
                            },
                        }
                    },
                },
            },
        },
        MuiTypography: { // adding custom defined typography variants to use them as `props`
            variants: [
                {
                    props: { variant: 'bodyXSmall' },
                    style: {
                        fontSize: bodyXSmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXSmallSize,
                    },
                },
                {
                    props: { variant: 'bodySmall' },
                    style: {
                        fontSize: bodySmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBSmallSize,
                    },
                },
                {
                    props: { variant: 'bodyMedium' },
                    style: {
                        fontSize: bodyMediumSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBMediumSize,
                    },
                },
                {
                    props: { variant: 'bodyLarge' },
                    style: {
                        fontSize: bodyLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBLargeSize,
                    },
                },
                {
                    props: { variant: 'bodyXLarge' },
                    style: {
                        fontSize: bodyXLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXLargeSize,
                    },
                },
            ],
        },
        MuiButton: {
            defaultProps: {
                // variant: "contained",
            },
            styleOverrides: {
                root: {
                    // borderRadius: "100px",
                    textTransform: "capitalize",
                    width: "100%",
                    fontWeight: 700,
                    textAlign: "center",
                    "@media (min-width: 960px)": {
                        width: "auto",
                    },
                },
                label: {
                    // fontFamily: '"GillSansNova-SemiBold", "Helvetica", "Arial", sans-serif',
                    // letterSpacing: "0.03em",
                },
                containedPrimary: {
                    background: primaryColor,
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                },
                containedSecondary: {
                    background: primaryColor,
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                },
                sizeMedium: {
                    padding: "10px 26px",
                },
                sizeSmall: {
                    fontSize: "12px",
                    "@media (min-width: 991px)": {
                        fontSize: "14px",
                    },
                },
            },
            variants: [
                {
                  props: { variant: 'white' },
                  style: {
                    background: "#fff",
                    boxShadow: "none",
                    fontWeight: 600,
                    "&:hover": {
                        background: secondayColor,
                        color: "#fff",
                    },
                  },
                },
            ],
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: bodyPrimaryColor
                },
            },
        },
        MuiPickersCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    "fontSize": bodySmallSize,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "fontSize": bodySmallSize,
                },
            },
        },
        MuiClock: {
            styleOverrides: {
                root: {
                    "position": "relative", // Fix to position AM/PM inside the clock area
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
        },
        MuiSelect: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    "backgroundColor": "#005480",
                    "padding": "12px 16px",
                },
                arrow: {
                    "&::before": {
                        "backgroundColor": "#005480",
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'large',
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "color": bodyPrimaryColor,
                },
            },
        }
    },
    typography: {
        htmlFontSize: 16,
        // fontFamily: 'Switzer', // for custom google font you need to include the <link> to the font in the header of the page, this is not done with MUI
        fontFamily: primaryFontFamily,

        fontWeightBold: 700,
        h1: {
            fontFamily: secondaryFontFamily,
            fontSize: h1Size,
            fontWeight: 600,
            letterSpacing: "-0.01em",
            lineHeight: h1LineHSize, // lineHeight / fontSize
        },
        h2: {
            fontFamily: secondaryFontFamily,
            fontSize: h2Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h2LineHSize,
        },
        h3: {
            fontFamily: secondaryFontFamily,
            fontSize: h3Size,
            fontWeight: 500,
            letterSpacing: "-0.01em",
            lineHeight: h3LineHSize,
        },
        h4: {
            fontFamily: secondaryFontFamily,
            fontSize: h4Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h4LineHSize,
        },
        h5: {
            fontFamily: secondaryFontFamily,
            fontSize: h5Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h5LineHSize,
        },
        h6: {
            fontFamily: secondaryFontFamily,
            fontSize: h6Size,
            fontWeight: 600,
            letterSpacing: "-0.01em",
            lineHeight: h6LineHSize,
        },
        body1: {
            fontSize: "1rem",
            letterSpacing: "-0.01em",
            lineHeight: 1.44,
        },
        caption: {
            fontSize: "0.6875rem",
        },
        button: {
            fontFamily: primaryFontFamily,
            letterSpacing: "0.03em",
            
        },
        tabs: {
            fontFamily: primaryFontFamily,
        }
    },

    // Defining global google fonts to be added in the <head>
    googleFont: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",

    primaryFontFamily: primaryFontFamily,
    secondaryFontFamily: secondaryFontFamily,
    tertiaryFontFamily: primaryFontFamily,
    saveSearchTextFontFamily: primaryFontFamily,

    // Global CSS implemneted by StarberryRootThemeProvider.
    "sx": {
        "@global":{
            "iframe": {
                "border": "none",
            },
            "body.floatingContainer-toolbar main": { // add additional spacing on bottom if we have floating toolbars
                "@media (max-width: 959px)": {
                    "paddingBottom": "10rem",
                },
            },
            "@font-face": [
                {
                    "fontFamily": "Futura",
                    "src": "url('/fonts/Futura/Medium.woff') format('woff'), url('/fonts/Futura/Medium.woff2') format('woff2')",
                    // "fontWeight": 400,
                    "fontStyle": "normal",
                },
                {
                    "fontFamily": "PTSans",
                    "src": "url('/fonts/PTSans/Regular.woff') format('woff'), url('/fonts/PTSans/Bold.woff') format('woff')",
                    "fontWeight": 400,
                    "fontStyle": "normal",
                }
            ],
        },
    },

    /**
     * END MUI GLOBAL OVERRIDES OF STYLES
     */


    /**
    * Starberry custom theme variables
    */

    // Sidebar drawer navigation
    "myAccountSetDrawerWidth": 340,
    "myAccountSetDrawerWidthXL": 400,

    // Main content area paddings
    "myAccountContentPTmobile": 2.25, // top spacing for the content container
    "myAccountContentPBmobile": 2.25, // bottom spacing for the content container

    "myAccountContentPTtablet": 4, // top spacing for the content container
    "myAccountContentPBtablet": 4, // bottom spacing for the content container

    "myAccountContentPTDesktop": 6, // top spacing for the content container
    "myAccountContentPBDesktop": 6, // bottom spacing for the content container

    // module border radius if its different then MUI border radius
    "moduleBorderRadius": "14px",
    // Global sidebar module padding
    "modulePadding": theme => theme.spacing(4,5),
    "moduleSpacing": theme => theme.spacing(2.6),
    "moduleSpacingSm": theme => theme.spacing(6),
    "moduleSpacingMd": theme => theme.spacing(6),
    "moduleSpacingLg": theme => theme.spacing(7),
    "moduleSpacingXl": theme => theme.spacing(7),

    /**
    * End Starberry custom theme variables
    */

    // The name of the theme.  This is used for naming elements in the
    // site.
    "name": themeName,

    // This is the theme name that helps to load theme specific items
    "themeName": themeName,

    // Site terms & privacy link
    "termsSiteLink": termsSiteLink,
    "privacySiteLink": privacySiteLink,

    // The company name, which can be used for the copyright message, and
    // anywhere else it might be needed.
    "companyName": companyName,

    // The copyright message of the site, for the footer.
    "copyright": theme => `© ${theme.year}, ${theme.companyName}${copyrightAltText}`,

    // Simple abstraction to make the copyright message above a little
    // easier to read.
    "year": `${(new Date()).getFullYear()}`,

    // Used by <Breadcrumbs>
    "breadcrumbSeparator": "… ",

    // Social media links used by <FooterSocial>
    "social": {
        "Facebook": facebook,
        "Instagram": instagram,
        "LinkedIn": linkedIn
    },

    "images": {
        "default": { method: "img" },
        "propertyresult": {
            "method": "img",
            "sx": {
                "& img": {
                    "width": "100%",
                    "height": "auto",
                    "transform": "scale(1)",
                    "transition": "transform .2s ease-out",
                },
                "&:hover": {
                    "transform": "scale(1.3)",
                }
            }
        }
    },
    "loginBg": loginBg,
    "logo": Logo,
    "logoSecondary": LogoSecondary,
    // Subthemes.  The values within a subtheme will overlay a copy of the
    // parent theme.  Subthemes can themselves contain subthemes.
    "subthemes": {
        "siteby": {
            "tweak": 0.075, // Align baseline of font so SiteByStarberry lines up.  This needs to be kludged for each particular site. :(
        },
        "content": {
            "props": {
                "direction": "column",
            },
            "contained": true,
        },
        "header": {
            "contained": true,

            "props": {
                "direction": "row",
                "alignItems": "middle",
                "justify": "space-between",
                "height": "5rem",
                "paddingTop": "1rem"
            },

            "sx": {
                "root": {
                    "backgroundColor": "black"
                }
            },

            "subthemes": {
                "logo": {
                    "props": {
                        "width": "10rem",
                        "justify": "left",
                        "sx": {

                        }
                    }
                },

                "menu": {
                    "type": "burger",
                    "props": {
                        "direction": "row",
                        "alignItems": "left",
                        "justify": "space-around",
                    },

                    "anchorOrigin":    { "horizontal": "center" },
                    "transformOrigin": { "horizontal": "center" },

                    "sx": {
                        "root": {
                            "& .HeaderMenuContainer": {
                                "display": "flex",
                                "flex-direction": "row",
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink": {
                                // "flex-direction": "row"
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink span": {
                                "font-weight": 600
                            },
                            "& .HeaderMenuLevel1 span": {
                            }
                        }
                    },

                    "menus": {
                        "Home": "/",
                        "About Us": "/about-us",
                        "Careers": "/careers",
                        "‍Work": "/our-work",
                        "Stories": "/stories",
                        "Contact Us": "/contact-us",
                        "Who We Work With": {
                            "Estate Agents": "/who-we-work-with/estate-agents",
                            "Property Developers": "/who-we-work-with/property-developers",
                            "Proptech": "/who-we-work-with/proptech",
                            "Finance": "/who-we-work-with/finance",
                            "Solicitors & Lawyers": "/who-we-work-with/legal",
                            "Architects": "/who-we-work-with/architects",
                            "Interior Design": "/who-we-work-with/interior-design"
                        },
                        "What We Do": {
                            "Branding": "/what-we-do/branding",
                            "Website Design": "/what-we-do/website-design",
                            "Website Development": "/what-we-do/website-development",
                            "Content Marketing": "/what-we-do/content-marketing",
                            "Video": "/what-we-do/video",
                            "Social Media": "/what-we-do/social-media",
                            "SEO": "/what-we-do/seo",
                            "Digital Advertising": "/what-we-do/digital-advertising",
                            "Email Marketing": "/what-we-do/email-marketing"
                        }
                    }
                }
            }
        },
        "footer": {
            "contained": "outer",
            "props": {
                "direction": "column",
            },

            "sx": {
                "root": {
                    "& a": {
                        "color": "currentColor"
                    },
                    "& ul": {
                        "margin": 0,
                        "padding": 0,
                        "list-style": "none",
                        "display": "block"
                    }
                }
            },

            "titleVariant": "button",
            "colors": {
                "background": "primary",
                "text": "#fff"
            },
            "menu": {
                "menus": {
                    "Menu": {
                        "Home": "/",
                        "About Us": "/about-us",
                        "Careers": "/careers",
                        "‍Work": "/our-work",
                        "Stories": "/stories",
                        "Contact Us": "/contact-us"
                    },
                    "Who We Work With": {
                        "Estate Agents": "/who-we-work-with/estate-agents",
                        "Property Developers": "/who-we-work-with/property-developers",
                        "Proptech": "/who-we-work-with/proptech",
                        "Finance": "/who-we-work-with/finance",
                        "Solicitors & Lawyers": "/who-we-work-with/legal",
                        "Architects": "/who-we-work-with/architects",
                        "Interior Design": "/who-we-work-with/interior-design"
                    },
                    "What We Do": {
                        "Branding": "/what-we-do/branding",
                        "Website Design": "/what-we-do/website-design",
                        "Website Development": "/what-we-do/website-development",
                        "Content Marketing": "/what-we-do/content-marketing",
                        "Video": "/what-we-do/video",
                        "Social Media": "/what-we-do/social-media",
                        "SEO": "/what-we-do/seo",
                        "Digital Advertising": "/what-we-do/digital-advertising",
                        "Email Marketing": "/what-we-do/email-marketing"
                    },
                    "Follow Us": {
                        "Facebook": facebook,
                        "Instagram": instagram,
                        "LinkedIn": linkedIn
                    }
                }
            }
        },
        "menuRouterHomePage": { // Specify which homepage version layout we want to use
            "props": {
                "pageVariant": MYACCOUNT_LIGHT ? "MyProperty" : "Dashboard"
            },
        },
        // "myAccount": {
            // "subthemes": {
                "bookingWidget":{ // options: src/core/components/MyAccount/property/components/iframeBookViewing/defaults.js
                },
                "imageBox": { // options: src/components/MyAccount/imageBox/defaults.js
                },
                "videoText": { // options: src/components/MyAccount/modules/videoText/defaults.js
                },
                "iconBox": { // options: src/components/MyAccount/iconBox/defaults.js
                },
                "static2column": { // options: src/components/MyAccount/modules/grid/defaults.js
                },
                "bgIcon": { // options: src/components/MyAccount/modules/bgIcon/defaults.js
                },
                "socialShare": { // options: src/components/MyAccount/socialShare/defaults.js
                },
                "backButton": { // options: src/components/MyAccount/backButton/defaults.js
                },
                "projectIcons": { // options: src/components/MyAccount/icons/defaults.js
                    "props": {
                        "logoIcon": CustomLogoIcon,
                    },
                },
                "sidebarDrawer": { // options: src/components/MyAccount/SidebarDrawerDefaults.js
                    "props": {
                        "menus": [
                            {
                                "hide": false,
                                "name": "Back to Website",
                                "icon": {
                                    "name": "backToWebsiteIcon",
                                    "props": {
                                        "color": "primary",
                                        "fontSize": "small",
                                    },
                                },
                                "link": `${WEBSITE_URL}`,
                                "newtab": true,
                                "menutype": "external",
                            },
                            {
                                "hide": MYACCOUNT_LIGHT ? true : false,
                                "name": "Dashboard",
                                "icon": {
                                    "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                                    "props": {
                                        "color": "primary",
                                        "fontSize": "small",
                                    },
                                },
                                "link": `${MYACCOUNT_DASHBOARD}`,
                            },
                            {
                                "hide": false,
                                "name": MYACCOUNT_LIGHT ? "Dashboard" : "My Property",
                                "icon": {
                                    "name": MYACCOUNT_LIGHT ? "dashboardIcon" : "myPropertyIcon",
                                },
                                "link": `${MYACCOUNT_MYPROPERTY}`,
                            },
                            {
                                "hide": false,
                                "name": "Inbox",
                                "icon": {
                                    "name": "inboxIcon",
                                },
                                "link": `${MYACCOUNT_INBOX}`,
                            },
                            {
                                "hide": false,
                                "name": "Calendar",
                                "icon": {
                                    "name": "calendarIcon",
                                },
                                "link": `${MYACCOUNT_CALENDAR}`,
                            },
                            {
                                "hide": false,
                                "name": "Profile",
                                "icon": {
                                    "name": "profileIcon",
                                },
                                "link": `${MYACCOUNT_PROFILE}`,
                            },
                            {
                                "hide": true,
                                "name": "Settings",
                                "icon": {
                                    "name": "settingsIcon",
                                },
                                "link": "#",
                            },
                            {
                                "hide": false,
                                "name": "Log out",
                                "icon": {
                                    "name": "logoutIcon",
                                },
                                "link": "#",
                                "funcName": "logout",
                            },
                        ],
                    },
                    "subthemes": {
                        "logo": {
                            "sx": (theme) => ({
                                "margin": theme.spacing(4, "auto", 2, "auto"),
                                "width": "146px",
                                "height": "auto",
                                [theme.breakpoints.up('md')]: {
                                    "width": "210px",
                                    "margin": theme.spacing(9, "auto", 9, "auto"),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    // "width": "132px",
                                    "margin": theme.spacing(7, "auto", 9, "auto"),
                                },
                            }),
                        },
                    },
                },
                "appBar": { // options: src/components/MyAccount/AppBarDefaults.js
                    "props": {
                        // "burgerButton": {
                        //     "color": "primary",
                        //     "aria-label": "open drawer",
                        //     "edge": "start",
                        //     "disableRipple": true,
                        //     "sx": {
                        //         "py": 0,
                        //         "marginRight": "0!important",
                        //         "&:hover": {
                        //             "background": "transparent",
                        //         },
                        //         "& svg": {
                        //             "width": "70px",
                        //             "height": "16px",
                        //         },
                        //     },
                        // },
                        "menusBottomFixed": [
                            {
                                "id": 1,
                                "hide": false,
                                "name": "Dashboard",
                                "icon": {
                                    "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                                    "props": {
                                        "color": "primary",
                                        "fontSize": "small",
                                    },
                                },
                                "link": `${MYACCOUNT_DASHBOARD}`,
                            },
                            {
                                "id": 2,
                                "hide": false,
                                "name": "My Property",
                                "icon": {
                                    "name": "myPropertyIcon",
                                },
                                "link": `${MYACCOUNT_MYPROPERTY}`,
                            },
                            {
                                "id": 3,
                                "hide": false,
                                "name": "Inbox",
                                "icon": {
                                    "name": "inboxIcon",
                                },
                                "link": `${MYACCOUNT_INBOX}`,
                            },
                            {
                                "id": 4,
                                "hide": false,
                                "name": "Calendar",
                                "icon": {
                                    "name": "calendarIcon",
                                },
                                "link": `${MYACCOUNT_CALENDAR}`,
                            },
                            {
                                "id": 5,
                                "hide": true    ,
                                "name": "Search",
                                "icon": {
                                    "name": "searchIcon",
                                },
                                "link": `${MYACCOUNT_CALENDAR}`,
                            },
                            {
                                "id": 6,
                                "hide": false,
                                "name": "Menu",
                                "icon": {
                                    "name": "mobileBurgerIcon",
                                },
                                "link": "#",
                                "funcName": "menu",
                            },
                        ]
                    }
                },
                "customSidebarDrawer": { // options: src/components/MyAccount/customSidebarDrawer/defaults.js
                },
                "globalComponentLayout": {
                },
                "globalDropdownActions": { // options: src/components/MyAccount/property/savedSearch/defaultsActions.js
                },
                "globalPageTitle": { // options: src/components/MyAccount/pageTitle/defaults.js
                    "sx": (theme) => ({
                        "display": "none",
                        "fontWeight": "600",
                        [theme.breakpoints.up('md')]: {
                            "display": "block",
                            "marginBottom": `${theme.spacing(4)} !important`,
                        },
                    }),
                },
                "globalForm": { // options: src/components/MyAccount/forms/defaults.js
                },
                "mainContent": { // options: src/components/MyAccount/ContentDefaults.js
                },
                "alertComponent": { // options: src/components/MyAccount/alert/defaults.js
                },
                "welcomeCard": { // options: src/components/MyAccount/user/welcome/defaults.js
                },
                "circularProgress": { // options: src/components/MyAccount/progress/defaults.js
                },
                "noActions": { // options: src/components/MyAccount/progress/NoActionsDefaults.js
                },
                "formTextField": {
                },
                "formSwitchField": { // options: src/components/MyAccount/forms/FormSwitchField/defaults.js
                },
                "loginSignup": { // options: src/components/MyAccount/modals/defaults.js
                    "props": {
                        "logoType": "logoSecondary",
                        "signup": {
                            "defaultCountry": defaultCountry,
                            "usePhoneField": true,
                        }
                    }
                },
                "backToWebsite": {   // options: src/components/MyAccount/modals/backToWebsite/defaults.js
                },
                "logoLogin": {  // options: src/components/MyAccount/Logo/defaults.js
                },
                "actionCardContainer": { // Wrapper around action cards component
                },
                "actionCard": { // options: src/components/MyAccount/actions/action/defaults.js - actionCardContainer/actionCard
                    "props": {
                        "cardAction": (theme) => ({
                            "color": theme.palette.text.primary,
                        }),
                    },
                    // "sx": (theme) => ({
                    //     "&.actionCard-card": {
                    //         "marginBottom": 0,
                    //         "paddingTop": theme.spacing(1),
                    //         "paddingBottom": theme.spacing(1),
                    //         "&.disabled": {
                    //             "opacity": 0.5
                    //         }
                    //     },
                    //     "& .actionCard-cardActionButton": {
                    //         "backgroundColor": theme.palette.grey.grey4,
                    //         "color": theme.palette.grey.grey1,
                    //     },
                    //     "& .actionCard-cardHeader": {
                    //         "padding": theme.spacing(1,0),
                    //         [theme.breakpoints.up('sm')]: {
                    //             "padding": theme.spacing(1.5,0),
                    //         },
                    //         "& .cardHeader-avatar": {
                    //             "marginLeft": "auto",
                    //             "marginRight": theme.spacing(1.875),
                    //             [theme.breakpoints.up('sm')]: {
                    //                 "marginRight": theme.spacing(3.5),
                    //             }
                    //         },
                    //         "& .cardHeader-title": {
                    //             "fontFamily": theme.secondaryFontFamily,
                    //             "color": theme.palette.grey.grey1,
                    //             "fontSize": theme.bodySmall,
                    //             "marginBottom": theme.spacing(0.25),
                    //             [theme.breakpoints.up('sm')]: {
                    //                 "fontSize": theme.bodyLarge,
                    //                 "lineHeight": theme.lineHeightBLarge,
                    //                 "marginBottom": theme.spacing(0.5),
                    //                 "fontWeight": 700,
                    //             }
                    //         },
                    //         "& .cardHeader-subheader": {
                    //             "color": theme.palette.grey.grey2,
                    //             [theme.breakpoints.up('sm')]: {
                    //                 "fontSize": theme.bodyMedium,
                    //                 "lineHeight": theme.lineHeightBMedium,
                    //             }
                    //         },
                    //         "& .cardHeader-action": {
                    //             "alignSelf": "center",
                    //             "marginRight": theme.spacing(0.5),
                    //         }
                    //     },
                    // })
                },
                "actionCardViewing": { // override options from `actionCard` subtheme
                },
                "actionCardOffer": { // override options from `actionCardOffer` subtheme
                    "props": {
                        "actionWithActionButtons": true
                    }
                },
                "actionCardTenancy": { // override options from `actionCardTenancy` subtheme
                    "props": {
                        "actionWithActionButtons": true
                    }
                },
                "actionCardFeedback": { // override options from `actionCardFeedback` subtheme
                },
                "actionCardDocument": { // override options from `actionCardDocument` subtheme
                },
                "actionCardWorksorder": { // override options from `actionCardWorksorder` subtheme
                    "props": {
                        "openFixfloUrl": fixfloUrl,
                    }
                },
                "actionCardInspection": { // override options from `actionCardInspection` subtheme
                },
                "actionCardAppointment": { // override options from `actionCardAppointment` subtheme
                },
                "dialogDefault": { // options: src/components/MyAccount/modals/default/defaults.js
                },
                "dialogPreference": { // options: src/components/MyAccount/user/propertyPreference/dialogDefaults.js
                },
                "dialogLogout": {// options: src/components/MyAccount/user/logoutDialog/defaults.js
                },
                "dialogChildren": { // options: src/components/MyAccount/property/components/reportFaultBtn/dialogChildrenDefaults.js
                },
                "actionCardDialog": { // options: src/components/MyAccount/modals/action/defaults.js
                    "subthemes": {
                        "contactModule": { // Overriding how the contact module renders in action dialog
                            "props": {
                                "cardModule": {
                                    "elevation": 0,
                                },
                                "cardModuleButtonTelProps": {
                                    "variant": "contained",
                                    "color": "secondary",
                                },
                                "cardModuleButtonEmailProps": {
                                    "variant": "contained",
                                    "color": "secondary",
                                },
                            },
                            // "sx": (theme) => ({
                            //     "& .contactModule": {
                            //         "padding": 0,
                            //         "borderRadius": 0,
                            //     },
                            //     "& .cardContent": {
                            //         "display": "flex",
                            //         "flexDirection": "column",
                            //         "alignItems": "center",
                            //         "padding": 0,
                            //         "textAlign": "center",
                            //     },
                            //     "& .cardModuleTitle": {
                            //         "marginBottom": theme.spacing(4),
                            //     },
                            //     "& .cardActions": {
                            //         "flexDirection": "column",
                            //         "padding": 0,
                            //         "marginTop": theme.spacing(3),
                            //         "& > div": {
                            //             "width": "100%",
                            //         }
                            //     },
                            //     "& .cardModuleUserTitle": {
                            //         "color": theme.palette.text.primary,
                            //     },
                            //     "& .cardModuleUserJobTitle": {
                            //         "color": theme.palette.text.primary,
                            //     },
                            //     "& .cardModuleButton": {
                            //         "width": "100%",
                            //         "marginTop": theme.spacing(2),
                            //     },
                            //     "& .avatar": {
                            //         "marginBottom": theme.spacing(2),
                            //         // "backgroundColor": "#ff0000",
                            //         "width": 80,
                            //         "height": 80,
                            //     },
                            //     "& .closeCard": {
                            //         "color": theme.palette.grey.grey2,
                            //         "marginTop": theme.spacing(3),
                            //     },
                            // }),
                        },
                    }
                },
                "actionDialogChildren": { // options: src/components/MyAccount/actions/action/defaults.js
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            }
                        },
                    },
                },
                "activityCard": { // options: src/components/MyAccount/activities/defaults.js
                },
                "profileCard": { // options: src/components/MyAccount/user/profileCard/defaults.js
                },
                "userAvatar": { // options: src/components/MyAccount/user/avatar/defaults.js
                },
                "tabsMyAccount": { // options: src/components/MyAccount/tabs/defaults.js
                },
                "calendar": { // options: src/components/MyAccount/calendar/defaults.js
                    // "sx": (theme) => ({
                    //     "& .calendar-paper": {
                    //         "padding": theme.spacing(2,0.5),
                    //         "borderRadius": theme.moduleBorderRadius,
                    //         [theme.breakpoints.up('lg')]: {
                    //             "marginTop": theme.spacing(5),
                    //         },
                    //     },
                    //     "& .calendar-dayPicker": {
                    //         "& .calendar-dayPicker-container": {
                    //             "& .MuiButtonBase-root": {
                    //                 "backgroundColor": theme.palette.grey.grey4,
                    //                 "&.Mui-selected": {
                    //                     "backgroundColor": theme.palette.primary.main,
                    //                 },
                    //                 "&.Mui-disabled": {
                    //                     "backgroundColor": "transparent",
                    //                     "color": "#ccc",
                    //                 },
                    //             },
                    //         },
                    //     },
                    // })
                },
                "simpleModule": { // options: src/components/MyAccount/simpleModule/defaults.js

                },
                "contactModule": { // options: src/components/MyAccount/contactModule/defaults.js
                    "props": {
                        "cardModuleButtonTelProps": {
                            "variant": "contained",
                            "color": "secondary",
                        },
                        "cardModuleButtonEmailProps": {
                            "variant": "contained",
                            "color": "secondary",
                        },
                    },
                },
                "simpleContactModule": { // options: src/components/MyAccount/contactModule/simpleContact.js
                },
                "menuListIcons": { // options: src/components/MyAccount/iconMenuList/menuListIcons.js
                },
                "menuListIconsTabs": { // options: src/components/MyAccount/iconMenuList/menuListIconsTabs.js
                },
                "savedProperties": {
                    "props": {
                        "showBoxContainer": true,
                        "noActionMessage": "Get started in just a few seconds",
                        "resultLinkFun": (p) => resultLinkFun(p),
                        "boxContainerBtnProps": {
                            "color": "secondary",
                            "variant": "contained",
                        },
                        "containerText": "Save property you like here. You can then return to it or share it at anytime."
                    },
                },
                "propertyResultComponent": {
                    "subthemes": {
                        "results": { // default property result styling
                            "sx": (theme) => ({
                                [theme.breakpoints.up('sm')]: {
                                    "width": "auto",
                                    "margin": theme.spacing(0,-0.75),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "margin": theme.spacing(0,-2.375),
                                },
                            }),
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "container": {
                                            "order": ['media', 'header', 'aside'],
                                            "sx": (theme) => ({
                                                "flexDirection": "row",
                                                "width": "100%",
                                                "paddingTop": theme.spacing(1.125),
                                                "paddingBottom": theme.spacing(1.125),
                                                [theme.breakpoints.up('sm')]: {
                                                    "flexDirection": "column",
                                                    "width": `calc(50% - ${theme.spacing(1.5)})`,
                                                    "paddingTop": 0,
                                                    "paddingBottom": 0,
                                                    "marginBottom": theme.spacing(5),
                                                    "marginLeft": theme.spacing(0.75),
                                                    "marginRight": theme.spacing(0.75),
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                                    "marginLeft": theme.spacing(2.375),
                                                    "marginRight": theme.spacing(2.375),
                                                },
                                            }),
                                        },
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "calc(100% - 110px)",
                                                [theme.breakpoints.up('sm')]: {
                                                    "width": "100%",
                                                    "marginTop": theme.spacing(3.25),
                                                },
                                                "& .propertyresult-address": {
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                    [theme.breakpoints.up('sm')]: {
                                                        "fontSize": theme.bodyLarge,
                                                        "lineHeight": theme.lineHeightBLarge,
                                                        "marginBottom": theme.spacing(0.25),
                                                    },
                                                },
                                            }),
                                        },
                                        "aside": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "display": "flex",
                                                "flexDirection": "row",
                                                "alignItems": "center",
                                                "justifyContent": "flex-end",
                                                "& .propertyresult-price": {
                                                    "marginRight": "auto",
                                                    [theme.breakpoints.up('sm')]: {
                                                        "fontSize": theme.bodyMedium,
                                                        "lineHeight": theme.lineHeightBMedium,
                                                    },
                                                },
                                            }),
                                        },
                                        // "price": {
                                        //     "in": "aside",
                                        //     "variant": "bodySmall",
                                        //     "component": "p",
                                        // },
                                        "address": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                            "component": "header",
                                        },
                                        "offer": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                        },
                                        "rooms": {
                                            "in": "header",
                                        },
                                        // "save": {
                                        //     "in": "aside",
                                        // },
                                        // "removeProperty": {
                                        //     "in": "aside",
                                        // },
                                        "media": {
                                            "sx": (theme) => ({
                                                "overflow": "hidden",
                                                "marginRight": theme.spacing(2.375),
                                                "& .propertyresult-link-image": {
                                                    "display": "block",
                                                },
                                                "& .propertyresult-image": {
                                                    "display": "block",
                                                    "width": "90px",
                                                    [theme.breakpoints.up('sm')]: {
                                                        "width": "100%",
                                                        "height": "250px",
                                                        "objectFit": "cover",
                                                    },
                                                    [theme.breakpoints.up('md')]: {
                                                        "height": "250px",
                                                    },
                                                    [theme.breakpoints.up('lg')]: {
                                                        "height": "200px",
                                                    },
                                                    [theme.breakpoints.up('xl')]: {
                                                        "height": "310px",
                                                    }
                                                },
                                                [theme.breakpoints.up('sm')]: {
                                                    "marginRight": 0,
                                                }
                                            })
                                        },
                                    },

                                    // "typography": {
                                    //     "h6": { "textTransform": "none" },
                                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                                    //     "subtitle2": { "textTransform": "none" },
                                    // },

                                    "addressFormat": {
                                        "separator": " , ",
                                        "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift()
                                        // "values": (p) => console.log(p)
                                    },
                                    "roomsFormat": {
                                        "separator": " ",
                                        "values": (p) => [p.bedrooms,p.receptions,p.bathrooms]
                                    },
                                },
                            },
                        },
                    },
                },
                "savedPropertyResultComponent":{
                    "subthemes": {
                        "results": { // default property result styling
                            "sx": (theme) => ({
                                [theme.breakpoints.up('sm')]: {
                                    "width": "auto",
                                    "margin": theme.spacing(0,-0.75),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "margin": theme.spacing(0,-2.375),
                                },
                            }),
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "container": {
                                            "order": ['media', 'header', 'aside'],
                                            "sx": (theme) => ({
                                                "flexDirection": "column",
                                                "width": "100%",
                                                "marginBottom": theme.spacing(3),
                                                [theme.breakpoints.up('sm')]: {
                                                    "width": `calc(50% - ${theme.spacing(1.5)})`,
                                                    "marginBottom": theme.spacing(5),
                                                    "marginLeft": theme.spacing(0.75),
                                                    "marginRight": theme.spacing(0.75),
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                                    "marginLeft": theme.spacing(2.375),
                                                    "marginRight": theme.spacing(2.375),
                                                },
                                            }),
                                        },
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "marginTop": theme.spacing(1.5),
                                                [theme.breakpoints.up('sm')]: {
                                                    "marginTop": theme.spacing(2.5),
                                                },
                                                [theme.breakpoints.up('xl')]: {
                                                    "marginTop": theme.spacing(3.25),
                                                },
                                                "& .propertyresult-address": {
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                    // "& a": {
                                                    //     "color": theme.palette.text.primary,
                                                    // },
                                                    [theme.breakpoints.up('sm')]: {
                                                        "fontSize": theme.bodyLarge,
                                                        "lineHeight": theme.lineHeightBLarge,
                                                        "marginBottom": theme.spacing(0.25),
                                                    },
                                                },
                                            }),
                                        },
                                        "aside": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "display": "flex",
                                                "flexDirection": "row",
                                                "alignItems": "center",
                                                // "justifyContent": "space-between",
                                                "marginTop": "auto",
                                                "flexWrap": "wrap",
                                                "& .propertyresult-price": {
                                                    "marginTop": theme.spacing(0),
                                                    "order": 1,
                                                    [theme.breakpoints.up('sm')]: {
                                                        // "marginTop": theme.spacing(0),
                                                        "fontSize": theme.bodyMedium,
                                                        "lineHeight": theme.lineHeightBMedium,
                                                    },
                                                    "maxWidth": "calc(100% - 50px)",
                                                },
                                                "& .propertyresult-rooms": {
                                                    "order": 0,
                                                    "flexBasis": "100%",
                                                    "marginTop": theme.spacing(0.75),
                                                    "& .roomsItem": {
                                                        "& svg": {
                                                            "color": theme.palette.primary.main
                                                        }
                                                    }
                                                },
                                                "& .propertyresult-removeProperty": {
                                                    "order": 2
                                                }
                                            }),
                                        },
                                        // "price": {
                                        //     "in": "aside",
                                        //     "variant": "bodySmall",
                                        //     "component": "p",
                                        // },
                                        "address": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                            "component": "header",
                                        },
                                        "offer": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                        },
                                        "rooms": {
                                            "in": "aside",
                                        },
                                        // "summary": {
                                        //     "in": "media",
                                        //     "variant": "bodyMedium",
                                        // },
                                        // "save": {
                                        //     "in": "aside",
                                        // },
                                        "removeProperty": {
                                            "in": "media",
                                        },
                                        // "media": {
                                        //     "sx": {
                                        //         "position": "relative",
                                        //         "overflow": "hidden",
                                        //         "&.propertyresult-media": {
                                        //             "width": "100%",
                                        //         },
                                        //         "& .propertyresult-link-image": {
                                        //             "display": "block",
                                        //             "&:after": {
                                        //                 "content": "''",
                                        //                 "position": "absolute",
                                        //                 "top": 0,
                                        //                 "left": 0,
                                        //                 "width": "100%",
                                        //                 "height": "100%",
                                        //                 "background": "rgba(0,0,0,0.2)",
                                        //                 "zIndex": "2",
                                        //                 "pointerEvents": "none",
                                        //             },
                                        //         },
                                        //         "& .propertyresult-image": {
                                        //             "display": "block",
                                        //             "width": "100%",
                                        //         },
                                        //         "& .propertyresult-removeProperty": {
                                        //             "position": "absolute",
                                        //             "top": "10px",
                                        //             "right": "10px",
                                        //             "zIndex": "20",
                                        //             "& svg": {
                                        //                 "color": "#fff",
                                        //             },
                                        //         },
                                        //         "& .propertyresult-360tour": {
                                        //             "position": "absolute",
                                        //             "bottom": "15px",
                                        //             "right": "15px",
                                        //             "width": "auto",
                                        //             "zIndex": 20,
                                        //         },
                                        //         "& .propertyresult-summary": {
                                        //             "textAlign": "center",
                                        //             "color": "#FFFFFF",
                                        //             "fontWeight": "500",
                                        //             "padding": "8px 20px",
                                        //             "zIndex": "3",
                                        //             "background": "linear-gradient(306.96deg, #00BCE4 -7.26%, #005480 92.06%)",
                                        //             "boxShadow": "0px 2px 12px rgba(0, 0, 0, 0.15)",
                                        //             "position": "absolute",
                                        //             "width": "100%"
                                        //         },
                                        //     },
                                        // },
                                    },

                                    // "typography": {
                                    //     "h6": { "textTransform": "none" },
                                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                                    //     "subtitle2": { "textTransform": "none" },
                                    // },

                                    "addressFormat": {
                                        "separator": " , ",
                                        "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift()
                                        // "values": (p) => console.log(p)
                                    },
                                    "roomsFormat": {
                                        "separator": " ",
                                        "values": (p) => [p.bedrooms,p.receptions,p.bathrooms]
                                    },
                                    "linkFormat": {
                                        "separator": "-",
                                        "target":"_blank",
                                        "values": (p) => propertyLink(p)
                                        // "values": (p) => console.log(p)
                                    },
                                    // "lettingsPricePrefix": "",
                                    "lettingsPriceSuffix": " PCM",
                                    // "salesPricePrefix": "",
                                    // "salesPriceSuffix": "",
                                },
                            },
                        },
                    },
                },
                "savedSearchResults": {
                    "props": {
                        "resultLinkFun": (p) => resultLinkFun(p),
                        "showBoxContainer": true,
                        "noactionTitle": "",
                        "noactionMessage": "Looks like you don’t have any searches saved yet"
                    },
                    // "sx": (theme) => ({
                    //     "& .content": {
                    //         "display": "flex",
                    //         "flexFlow": "column",
                    //     },
                    //     "& .box-container": {
                    //         "display": "flex",
                    //         "flexWrap": "wrap",
                    //         "alignItems": "center",
                    //         "border": "1px solid #DDDDDD",
                    //         "borderRadius": "4px",
                    //         "padding": theme.spacing(5),
                    //         "order": "1",
                    //         "& .box-image": {
                    //             "background": theme.palette.grey.grey3,
                    //             "& svg": {
                    //                 "fontSize": "2rem",
                    //                 "color": theme.palette.primary.icon
                    //             }
                    //         },
                    //     },
                    //     "& .box-image-container": {
                    //         "display": "none",
                    //         [theme.breakpoints.up('lg')]: {
                    //             "display": "block"
                    //         },
                    //     },
                    //     "& .box-image-container, .box-btn-container, .box-content": {
                    //         [theme.breakpoints.down('lg')]: {
                    //             "flexBasis": "100%",
                    //             "maxWidth": "100%",
                    //         },
                    //     },
                    //     "& .box-content": {
                    //         [theme.breakpoints.down('lg')]: {
                    //             "padding-bottom": "10px",
                    //             "flexBasis": "100%",
                    //             "maxWidth": "100%",
                    //         },
                    //         [theme.breakpoints.up('lg')]: {
                    //             "paddingLeft": "10px",
                    //         },
                    //     },
                    //     "& .box-btn-container": {
                    //         "textAlign": "left",
                    //         "paddingTop": theme.spacing(1),
                    //         [theme.breakpoints.up('lg')]: {
                    //             "textAlign": "right",
                    //             "paddingTop": theme.spacing(0),
                    //         }
                    //     },
                    //     "& .box-container-title": {
                    //         // "fontFamily": theme.primaryFontFamily,
                    //         // "fontWeight": theme.typography.fontWeightBold,
                    //         // "fontSize": theme.bodyMediumSize,
                    //         // "lineHeight": theme.h5LineH,
                    //         // "marginBottom": "5px",
                    //         // [theme.breakpoints.up('xl')]: {
                    //         //     "marginBottom": "10px",
                    //         // },
                    //     },
                    //     "& .box-container-text": {
                    //         "fontFamily": theme.secondaryFontFamily,
                    //         "fontSize": theme.bodyMedium,
                    //         "lineHeight": theme.lineHeightBMedium,
                    //         "fontWeight": "normal",
                    //         [theme.breakpoints.down('md')]: {
                    //             "marginBottom": "5px",
                    //             "display": "block"
                    //         },
                    //         "color": theme.palette.grey.grey2
                    //     },
                    //     "& .savedSearchResults-card": {
                    //         "marginBottom": 0,
                    //         "paddingTop": theme.spacing(1),
                    //         "paddingBottom": theme.spacing(1),
                    //         "&:last-child": {
                    //             "marginBottom": theme.spacing(4),
                    //         },
                    //         "&.disabled": {
                    //             "& .savedSearchResults-cardHeader": {
                    //                 "& > div": {
                    //                     "opacity": 0.5
                    //                 },
                    //                 "& .savedSearchResults-action": {
                    //                     "opacity": 1
                    //                 }
                    //             },
                    //         }
                    //     },
                    //     "& .savedSearchResults-cardActionButton": {
                    //         "backgroundColor": theme.palette.grey.grey4,
                    //         "color": theme.palette.primary.dark,
                    //         [theme.breakpoints.up('md')]: {
                    //             "width": "80px",
                    //             "height": "80px",
                    //         },
                    //     },
                    //     "& .savedSearchResults-cardHeader": {
                    //         "padding": theme.spacing(1,0),
                    //         [theme.breakpoints.up('sm')]: {
                    //             "padding": theme.spacing(1.5,0),
                    //         },
                    //         "& .savedSearchResults-avatar": {
                    //             "display": "none",
                    //             "marginRight": theme.spacing(1.875),
                    //             [theme.breakpoints.up('sm')]: {
                    //                 "marginRight": theme.spacing(3.5),
                    //             }
                    //         },
                    //         "& .savedSearchResults-title": {
                    //             "fontSize": theme.bodySmall,
                    //             "marginBottom": theme.spacing(0.25),
                    //             "fontWeight": "normal",
                    //             [theme.breakpoints.up('sm')]: {
                    //                 "fontSize": theme.bodyLarge,
                    //                 "lineHeight": theme.lineHeightBLarge,
                    //                 "marginBottom": theme.spacing(0.5),
                    //             }
                    //         },
                    //         "& .savedSearchResults-subheader": {
                    //             "color": theme.palette.grey.grey1,
                    //             [theme.breakpoints.up('sm')]: {
                    //                 "fontSize": theme.bodyMedium,
                    //                 "lineHeight": theme.lineHeightBMedium,
                    //             }
                    //         },
                    //         "& .savedSearchResults-action": {
                    //             "alignSelf": "center",
                    //             "marginRight": theme.spacing(0.5),
                    //         }
                    //     },
                    //     "& .savedSearchResults-cardContent": {
                    //         "display": "none",
                    //         [theme.breakpoints.up("lg")]: {
                    //             "display": "flex",
                    //         },
                    //         "& .cardContentItemName": {
                    //             // "fontWeight": theme.typography.fontWeightBold,
                    //             "fontWeight": 700,
                    //             "color": theme.palette.grey.grey1,
                    //         },
                    //         "& .cardContentItemParams": {
                    //             "color": theme.palette.grey.grey2,
                    //             "textTransform": "capitalize",
                    //         }
                    //     },
                    // }),
                },
                "latestProperties": {
                    "subthemes": {
                        "results": {
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "container": {
                                            "direction": "row",
                                            "span": "full",
                                            "order": ['media', 'header'],
                                            "sx": (theme) => ({
                                                "paddingTop": theme.spacing(1.125),
                                                "paddingBottom": theme.spacing(1.125),
                                                "marginBottom": theme.moduleSpacing,
                                                [theme.breakpoints.up('sm')]: {
                                                    "paddingTop": theme.spacing(3.375),
                                                    "paddingBottom": theme.spacing(3.375),
                                                    "marginBottom": theme.moduleSpacingSm,
                                                    "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    "marginBottom": theme.moduleSpacingLg,
                                                },
                                                "&:last-child": {
                                                    "borderBottom": "none",
                                                },
                                            }),
                                        },
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "calc(100% - 110px)",
                                                "& .propertyresult-address": {
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                },
                                            }),
                                        },
                                        // "price": {
                                        //     "in": "header",
                                        //     "variant": "bodySmall",
                                        // },
                                        "address": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                            "component": "header",
                                            "sx": (theme) => ({
                                                "root": {
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                }
                                            }),
                                        },
                                        "offer": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                        },
                                        "media": {
                                            "overflow": "hidden",
                                            "width": 90,
                                            "sx": (theme) => ({
                                                "marginRight": theme.spacing(2.375),
                                                "& .propertyresult-link-image": {
                                                    "display": "block",
                                                },
                                                "& .propertyresult-image": {
                                                    "display": "block",
                                                },
                                            })
                                        },
                                    },

                                    // "typography": {
                                    //     "h6": { "textTransform": "none" },
                                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                                    //     "subtitle2": { "textTransform": "none" },
                                    // },

                                    "address": {
                                        "separator": " • ",
                                        // "values": (p) => [p.district,p.street_name].filter(x=>x).shift()
                                        "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                                    },
                                },
                            },
                        },
                    },
                },
                "singleProperty": {
                    "props": {
                        publishStatuses: ["for sale", "sale agreed", "to let", "let agreed", "let"],
                    },
                    "sx": (theme) => ({
                        "root": {
                            "padding": theme.spacing(4,5),
                            "borderRadius": theme.moduleBorderRadius,
                            [theme.breakpoints.up('lg')]: {
                                "marginTop": theme.spacing(0),
                            }
                        },
                    }),
                    "subthemes": {
                        "results": {
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "container": {
                                            "direction": "column",
                                            "order": ['media', 'header'],
                                            // "sx": (theme) => ({
                                            //     "paddingTop": theme.spacing(1.125),
                                            //     "paddingBottom": theme.spacing(1.125),
                                            //     [theme.breakpoints.up('sm')]: {
                                            //         "paddingTop": theme.spacing(3.375),
                                            //         "paddingBottom": theme.spacing(3.375),
                                            //         "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                            //     },
                                            //     "&:last-child": {
                                            //         "borderBottom": "none",
                                            //     },
                                            // }),
                                        },
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "& .propertyresult-address": {
                                                    "marginTop": theme.spacing(3.25),
                                                    "marginBottom": theme.spacing(0.25),
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                },
                                            }),
                                        },
                                        // "summary": {
                                        //     "in": "media",
                                        //     "variant": "bodyMedium",
                                        // },
                                        "offer": {
                                            "in": "header",
                                            "variant": "bodyMedium"
                                        },
                                        "address": {
                                            "in": "header",
                                            "variant": "bodyLarge",
                                            "component": "header",
                                        },
                                        "media": {
                                            "sx": (theme) => ({
                                                "overflow": "hidden",
                                                "position": "relative",
                                                "& .propertyresult-link-image": {
                                                    "display": "block",
                                                },
                                                "& .propertyresult-image": {
                                                    "display": "block",
                                                    "width": "100%",
                                                    "height": "auto",
                                                },
                                                "& .propertyresult-summary": {
                                                    "textTransform": "capitalize",
                                                    "position": "absolute",
                                                    "top": 0,
                                                    "left": 0,
                                                    "width": "100%",
                                                    "backgroundColor": "#4F4F4F",
                                                    "zIndex": 2,
                                                    "textAlign": "center",
                                                    "color": "#fff",
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                    "padding": theme.spacing(1),
                                                }
                                            })
                                        },
                                    },

                                    // "typography": {
                                    //     "h6": { "textTransform": "none" },
                                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                                    //     "subtitle2": { "textTransform": "none" },
                                    // },

                                    // "address": {
                                    //     "separator": " • ",
                                    //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                                    // },
                                },
                            },
                        },
                    },
                },
                "myPropertyPageSingleHeaderProperty": {
                    "sx": (theme) => ({
                        "width": `calc(100% + (${theme.gridItemPmobile} * 2))`,
                        "marginLeft": `-${theme.gridItemPmobile}`,
                        "marginRight": `-${theme.gridItemPmobile}`,
                        "marginBottom": 0,
                        [theme.breakpoints.up('sm')]: {
                            "width": `calc(100% + (${theme.gridItemPtablet} * 2))`,
                            "marginLeft": `-${theme.gridItemPtablet}`,
                            "marginRight": `-${theme.gridItemPtablet}`,
                            "marginBottom": 0,
                        },
                    }),
                    "subthemes": {
                        "globalComponentLayout": {
                            "sx": {
                                // Remove global space between components and modules
                                "marginBottom": 0,
                            },
                        },
                        "singleProperty": {
                            "sx": (theme) => ({
                                "root": {
                                    "padding": 0,
                                    "margin": 0,
                                },
                            }),
                            "subthemes": {
                                "results": {
                                    "subthemes": {
                                        "propertyresult": {
                                            "props": {
                                                "header": {
                                                    "sx": (theme) => ({
                                                        "width": "100%",
                                                        "& .propertyresult-address": {
                                                            "display": "none",
                                                            "fontWeight": "normal",
                                                            "margin": 0,
                                                            "padding": `0 ${theme.gridItemPtablet}`,
                                                            [theme.breakpoints.up('sm')]: {
                                                                "display": "flex",
                                                                "padding": `0 ${theme.gridItemPtablet}`,
                                                                "marginBottom": theme.spacing(4),
                                                                "marginTop": theme.spacing(3.75),
                                                            },
                                                        },
                                                        "& .propertyresult-offerText": {
                                                            "display": "none",
                                                            [theme.breakpoints.up('md')]: {
                                                                "display": "flex"
                                                            },
                                                        },
                                                    }),
                                                },
                                                "address": {
                                                    "in": "header",
                                                    "variant": "h3",
                                                    "component": "h2",
                                                },
                                                "media": {
                                                    "sx": (theme) => ({
                                                        "position": "relative",
                                                        "overflow": "hidden",
                                                        "& .propertyresult-link-image": {
                                                            "display": "block",
                                                        },
                                                        "& .propertyresult-image": {
                                                            "overflow": "hidden",
                                                            "display": "block",
                                                            "width": "100%",
                                                            "height": "auto",
                                                            "marginBottom": 0,
                                                        },
                                                        "& .propertyresult-summary": {
                                                            "textTransform": "capitalize",
                                                            "position": "absolute",
                                                            "top": 0,
                                                            "left": 0,
                                                            "width": "100%",
                                                            "backgroundColor": "#4F4F4F",
                                                            "zIndex": 2,
                                                            "textAlign": "center",
                                                            "color": "#fff",
                                                            "fontWeight": theme.typography.fontWeightBold,
                                                            "padding": theme.spacing(1),
                                                        }
                                                    })
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                "profileMyAccountForm": { // options: src/components/MyAccount/user/profile/defaults.js
                },
                "profileMyAccountChangePassword": { // options: src/components/MyAccount/user/password/defaults.js
                },
                "profileMyAccountNotificationPreferences": {
                },
                "profileDeleteAccount": {
                },
                "pendingItems": { // options: src/components/MyAccount/progress/defaults.js
                },
                "feedbackProgress": { // options: src/components/MyAccount/feedbackProgress/defaults.js
                    "subthemes": {
                        "globalComponentLayout": {
                            "props": {
                                "containerProps": {
                                    "elevation": 2,
                                },
                                "contentProps": {

                                },
                                "titleProps": {
                                    "variant": "caption",
                                    "component": "h2",
                                },
                                "titleLinkMoreProps": {
                                    "variant": "caption",
                                },
                                "titleLinkMoreTooltipProps": {
                                    "title": "Tooltip",
                                    "placement": "top-end",
                                    "arrow": true,
                                },
                            },
                            "sx": (theme) => ({
                                "padding": 0,
                                "borderRadius": theme.moduleBorderRadius,
                                [theme.breakpoints.down('sm')]: {
                                    "boxShadow": "none",
                                },
                                [theme.breakpoints.up('sm')]: {
                                    "padding": theme.modulePadding,
                                },
                                "& .globalComponentLayout-titleContainer": {
                                    "display": "flex",
                                    "flexDirection": "row",
                                    "alignItems": "flex-end",
                                    "minHeight": 56,
                                    "paddingBottom": theme.spacing(2),
                                    [theme.breakpoints.up('sm')]: {
                                        "paddingBottom": theme.spacing(3),
                                    },

                                },
                                "& .globalComponentLayout-title": {
                                    "fontWeight": theme.typography.fontWeightBold,
                                    // "color": theme.palette.grey.grey6,
                                    [theme.breakpoints.up('sm')]: {
                                        "fontSize": theme.h6,
                                        "lineHeight": theme.h6LineH,
                                        // "color": theme.palette.text.primary,
                                    },
                                },
                                "& .globalComponentLayout-titleLinkMore": {
                                    "fontWeight": theme.typography.fontWeightBold,
                                    "color": theme.palette.grey.grey2,
                                    "marginLeft": "auto",
                                    [theme.breakpoints.up('sm')]: {
                                        "fontSize": theme.bodyMedium,
                                        "lineHeight": theme.lineHeightBMedium,
                                        "fontWeight": "normal",
                                        "color": theme.palette.text.primary,
                                    },
                                },
                            }),
                        }
                    }
                },
                "swipeEdge": { // options: src/components/MyAccount/customSidebarDrawer/swipeEdgeDefaults.js
                    "subthemes": {
                        "menuListIconsTabs": {
                            "sx": (theme) => ({
                                "& .menuListIconsTabs": {
                                    "padding": 0,
                                    "margin": 0,
                                },
                                "& .menuListIconsTabs-vertical": {
                                },
                                "& .menuListIconsTabs-indicator": {
                                    "display": "none",
                                },
                                "& .menuListIconsTabs-tab": {
                                    "fontWeight": theme.typography.fontWeightBold,
                                    "textTransform": "none",
                                    "minHeight": "58px",
                                    "borderBottom": `1px solid ${theme.palette.divider}`,
                                    "paddingTop": theme.spacing(1.5),
                                    "paddingBottom": theme.spacing(1.5),
                                    "paddingLeft": 0,
                                    "paddingRight": theme.gridItemPmobile,
                                    "opacity": 1,
                                    [theme.breakpoints.up('sm')]: {
                                        "fontSize": theme.bodyMedium,
                                        "lineHeight": theme.lineHeightBMedium,
                                        // "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(1.5,0),
                                    },
                                    "&.menuListIconsTabs-tab-wrapper": {
                                        "display": "flex",
                                        "flexDirection": "row",
                                        "alignItems": "center",
                                        "justifyContent": "flex-start",
                                    },
                                },
                                "& .menuListIconsTabs-tab-labelIcon svg": {
                                    "color": theme.palette.text.primary,
                                    "marginBottom": 0,
                                    "marginRight": theme.spacing(2.25),
                                }
                            })
                        },
                    },
                },
                "reportFaultBtn": { // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
                    "props" : {
                        "fixflo": {
                            "url" : fixfloPluginUrl
                        }
                    },
                    "subthemes": {
                        "dialogChildren": {
                            "subthemes": {
                                "globalForm": { // Overriding how the globalForm renders in action dialog
                                    "props": {
                                        "formButton": {
                                            "variant": "contained",
                                            "color": "primary",
                                            "fullWidth": true,
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                "newDocumentBtn": { // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
                    "props" : {
                    },
                    "subthemes": {
                        "dialogChildren": {
                            "subthemes": {
                                "globalForm": { // Overriding how the globalForm renders in action dialog
                                    "props": {
                                        "formButton": {
                                            "variant": "contained",
                                            "color": "primary",
                                            "fullWidth": true,
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                "simpleValuationModule": {
                    "subthemes": {
                        "dialogChildren": {
                            "subthemes": {
                                "globalForm": { // Overriding how the globalForm renders in action dialog
                                    "props": {
                                        "formButton": {
                                            "variant": "contained",
                                            "color": "primary",
                                            "fullWidth": true,
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                "contactBtn": { // options: src/components/MyAccount/property/components/contactBtn/defaults.js
                    "subthemes": {
                        "dialogChildren": {
                            "subthemes": {
                                "globalForm": { // Overriding how the globalForm renders in action dialog
                                    "props": {
                                        "formButton": {
                                            "variant": "contained",
                                            "color": "primary",
                                            "fullWidth": true,
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                "makeOfferBtn": { // options: src/components/MyAccount/property/components/makeOffer/defaults.js
                    "subthemes": {
                        "dialogChildren": {
                            "subthemes": {
                                "globalForm": { // Overriding how the globalForm renders in action dialog
                                    "props": {
                                        "formButton": {
                                            "variant": "contained",
                                            "color": "primary",
                                            "fullWidth": true,
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                "bookViewingBtn": { // options: src/components/MyAccount/property/components/bookViewing/defaults.js
                    "subthemes": {
                        "dialogChildren": {
                            "subthemes": {
                                "globalForm": { // Overriding how the globalForm renders in action dialog
                                    "props": {
                                        "formButton": {
                                            "variant": "contained",
                                            "color": "primary",
                                            "fullWidth": true,
                                        },
                                    }
                                },
                            },
                        },
                    },
                },
                "removePropertyPersona": { // options: src/components/MyAccount/property/components/removePropertyPersona/defaults.js
                    "subthemes": {
                        "dialogDefault": {
                            "props": {
                                "dialogTitleProps": {
                                    "align": "left"
                                },
                            },
                            "sx": (theme) => ({
                                "& .dialogDefault-container": {
                                },
                                "& .dialogDefault-paper": {
                                    [theme.breakpoints.up('md')]: {
                                        "borderRadius": "6px",
                                    },
                                    "& .MuiDialogTitle-root": { // This is the dialog title container
                                        "& .MuiTypography-root": { // This is the title
                                            "marginRight": "20px",
                                            "textAlign": "center",
                                        },
                                    },
                                },
                                "& .dialogDefault-paperFullScreen": {
                                    "borderRadius": "6px 6px 0 0",
                                },
                                "& .dialogDefault-close": {
                                    "position": "absolute",
                                    "top": "20px",
                                    "right": "20px",
                                    "width": "24px",
                                    "height": "24px",
                                    "zIndex": 9999,
                                },
                                "& .dialogDefault-content": {}
                            })
                        }
                    }
                },
                "homeReportBtn": { // options: src/components/MyAccount/property/components/homeReport/defaults.js
                },
                "floatingContainer": { // options: src/components/MyAccount/floatingContainer/defaults.js
                },
                "fabBtn": { // options: src/components/MyAccount/fabBtn/defaults.js
                    "props": {
                        "showBoxContainer": true,
                        "boxContainerBtnProps": {
                            "color": "secondary",
                            "variant": "contained",
                        },
                        "containerText": "Set up your search criteria to ensure you receive notifications of the most suitable property as soon as they come onto the market",
                        "defaultArea": defaultArea,
                        "defaultSearchType": "sales",
                    },
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            }
                        },
                    },
                },
                "currentTenancy": { // options: src/components/MyAccount/currentTenancy/defaults.js
                },
                "payments": { // options: src/components/MyAccount/payments/defaults.js
                },
                "newsBannerImage": { // options: src/components/MyAccount/blog/components/bannerImage/defaults.js
                },
                "homePage": {
                },
                "homePage2": {
                    "staticModules": [
                        {
                            "imageBox": {
                                "title": "Our Services",
                                "moreLabel": null,
                                "moreLinkUrl": null,
                                "titleLinkMoreTooltipProps": null,
                                "items": [
                                    {
                                        "title": "Find a Property for Sale",
                                        "subtitle": "Let’s find your new home together",
                                        "linkUrl": `${tileForSaleLink}`,
                                        "imgUrl": `${tileForSaleImg}`,
                                        "imgAlt": "",
                                    },
                                    {
                                        "title": "Find a Property to Rent",
                                        "subtitle": "Let’s find your new home together",
                                        "linkUrl": `${tileToRentLink}`,
                                        "imgUrl": `${tileToRentImg}`,
                                        "imgAlt": "",
                                    },
                                    {
                                        "title": "Contact Us",
                                        "subtitle": "Speak to one of our experts",
                                        "linkUrl": `${tileTeamLink}`,
                                        "imgUrl": `${tileTeamImg}`,
                                        "imgAlt": "",
                                    },
                                ],
                            },
                        },
                    ],
                },
                "myPropertyPage": {
                    "props": {
                        "tab":{
                            "default": MYACCOUNT_LIGHT ? "wishlist" : '',
                            // This can be a array or objects
                            "items": MYACCOUNT_LIGHT ? ['wishlist', 'saved-search'] : ['all-properties', 'selling', 'letting', 'renting', 'buying', 'wishlist', 'saved-search', 'archived'],
                        }
                    },
                    "staticModules": [
                        {
                            "videoText": {
                                "title": null,
                                "moreLabel": null,
                                "moreLinkUrl": null,
                                "titleLinkMoreTooltipProps": null,
                                "items": [
                                    {
                                        "containerProps": {
                                            "item": true,
                                            "spacing": 0,
                                            "flexDirection": ["column","column","row"],
                                        },
                                        "itemTextContainerProp": {
                                            "item": true,
                                            "xs": 12,
                                            "md": 8,
                                            "order": [1,1,1],
                                        },
                                        "itemImageContainerProps": {
                                            "item": true,
                                            "xs": 12,
                                            "md": 4,
                                            "order": [0,0,0],
                                        },
                                        "title": "Experts in mortgage and insurance",
                                        "text": "We pride ourselves on our personal approach to financial services. When you work with Linley & Simpson, you can trust that our team will take the time to get to know you and your circumstances.",
                                        "buttons": [
                                            // {
                                            //     "type": "tel",
                                            //     "label": `Call us on ${telephone}`,
                                            //     "url": `tel: ${telephone}`,
                                            //     "icon": "PhoneIcon",
                                            //     "linkProps": {
                                            //         "color": "inherit",
                                            //     },
                                            // },
                                            {
                                                "type": "email",
                                                "label": "Email us directly",
                                                "url": `mailto:${mailto}`,
                                                "icon": "EmailIcon",
                                                "linkProps": {
                                                    "color": "inherit",
                                                },
                                            }
                                        ],
                                        "linkUrl": `${tileMortgageLink}`,
                                        "imgUrl": `${tileMortgageImg}`,
                                        "imgAlt": "",
                                        "target":"_blank",
                                        // "videoUrl": "https://www.youtube.com/watch?v=BnfTkdrWzAs",
                                        "lightboxType": "image", // Type of "image", "video", "youtube",
                                    },
                                ],
                            },
                        },
                    ]
                },
                "myPropertyPageSeller": {
                    "props": {
                        "tab":{
                            "items": ['overview', 'appointments', 'feedback', 'offers'/*, 'documents'*/],
                        }
                    },
                    "subthemes": {
                        "mainContent": { // Remove top content spacing on mobile
                            "sx": (theme) => ({
                                "display": "flex",
                                "flexDirection": "column",
                                "width": "100%",
                                "flexGrow": 1,
                                "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                                [theme.breakpoints.up('sm')]: {
                                    "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                                },
                                [theme.breakpoints.up('md')]: {
                                    // "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                                    "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                                },
                                "& .mainContent-wrapper": {
                                    "flexGrow": 1,
                                },
                                "& .mainContent-toolbar": theme.mixins.toolbar,
                            }),
                        },
                        "tabsMyAccount": {
                            "subthemes": {
                                "tabsMyAccount": {
                                    "props": {
                                        "tabLevel": 2,
                                    }
                                },
                            },
                        },
                    },
                },
                "myPropertyPageLandlord": {
                    "props": {
                        "landlord": {
                            "showPendingAction": false
                        },
                        "tab":{
                            "items": ['overview', 'tenancy', 'fault-and-issues', 'documents', 'appointments', 'feedback', 'transactions'],
                        }
                    },
                    "subthemes": {
                        "mainContent": { // Remove top content spacing on mobile
                            "sx": (theme) => ({
                                "display": "flex",
                                "flexDirection": "column",
                                "width": "100%",
                                "flexGrow": 1,
                                "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                                [theme.breakpoints.up('sm')]: {
                                    "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                                },
                                [theme.breakpoints.up('md')]: {
                                    "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                                    "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                                },
                                [theme.breakpoints.up('xl')]: {
                                    "width": `calc(100% - ${theme.myAccountSetDrawerWidthXL}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                                },
                                "& .mainContent-wrapper": {
                                    "flexGrow": 1,
                                },
                                "& .mainContent-toolbar": theme.mixins.toolbar,
                            }),
                        },
                        "tabsMyAccount": {
                            "subthemes": {
                                "tabsMyAccount": {
                                    "props": {
                                        "tabLevel": 2,
                                    }
                                },
                            },
                        },
                    },
                },
                "calendarPage": {
                },
                "inbox": {
                },
                "loginPage": {
                },
                "signUpPage": {
                },
                "unsubscribePage": {
                },
                "resetPassEmailPage": {
                },
                "resetPassPage": {
                },
                "profilePage": {
                    "props": {
                        "conversationalFormEnabled": true
                    }
                },
                "myPropertyPageTenant": {
                    "props": {
                        "tenant": {
                            "tabOfferOfferBtn": true,
                            "showAddIcon": true,
                            "showBookaViewingBtn": true,
                            "showMakeAnOfferBtn": false
                        },
                        "tab":{
                            "items": ['overview', 'fault-and-issues', 'documents', 'transactions', 'appointments', 'feedback'],
                        }
                    },
                    "subthemes": {
                        "mainContent": { // Remove top content spacing on mobile
                            "sx": (theme) => ({
                                "display": "flex",
                                "flexDirection": "column",
                                "width": "100%",
                                "flexGrow": 1,
                                "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                                [theme.breakpoints.up('sm')]: {
                                    "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                                },
                                [theme.breakpoints.up('md')]: {
                                    "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                                    "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                                },
                                [theme.breakpoints.up('xl')]: {
                                    "width": `calc(100% - ${theme.myAccountSetDrawerWidthXL}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                                },
                                "& .mainContent-wrapper": {
                                    "flexGrow": 1,
                                },
                                "& .mainContent-toolbar": theme.mixins.toolbar,
                            }),
                        },
                        "tabsMyAccount": {
                            "subthemes": {
                                "tabsMyAccount": {
                                    "props": {
                                        "tabLevel": 2,
                                    }
                                },
                            },
                        },
                    },
                },
                "myPropertyPageBuyer": {
                    "props": {
                        "buyer": {
                            "tabOfferOfferBtn": true,
                            "showAddIcon": true,
                            "showBookaViewingBtn": false,
                            "showMakeAnOfferBtn": true
                        },
                    },
                    "subthemes": {
                        "mainContent": { // Remove top content spacing on mobile
                            "sx": (theme) => ({
                                "display": "flex",
                                "flexDirection": "column",
                                "width": "100%",
                                "flexGrow": 1,
                                "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                                [theme.breakpoints.up('sm')]: {
                                    "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                                },
                                [theme.breakpoints.up('md')]: {
                                    "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                                    "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                                },
                                [theme.breakpoints.up('xl')]: {
                                    "width": `calc(100% - ${theme.myAccountSetDrawerWidthXL}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                                },
                                "& .mainContent-wrapper": {
                                    "flexGrow": 1,
                                },
                                "& .mainContent-toolbar": theme.mixins.toolbar,
                            }),
                        },
                        "tabsMyAccount": {
                            "subthemes": {
                                "tabsMyAccount": {
                                    "props": {
                                        "tabLevel": 2,
                                    }
                                },
                            },
                        },
                    },
                },
                "propertyResultsPageFilters": { // options: src/components/MyAccount/pages/properties/defaults.js
                },
                "propertyResultsPage": {
                    "subthemes": {
                        "results": { // default property result styling
                            "sx": (theme) => ({
                                [theme.breakpoints.up('sm')]: {
                                    "width": "auto",
                                    "margin": theme.spacing(0,-0.75),
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "margin": theme.spacing(0,-2.375),
                                },
                            }),
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "container": {
                                            "order": ['media', 'header', 'aside'],
                                            "sx": (theme) => ({
                                                "flexDirection": "column",
                                                "width": "100%",
                                                "marginBottom": theme.spacing(3),
                                                [theme.breakpoints.up('sm')]: {
                                                    "width": `calc(50% - ${theme.spacing(1.5)})`,
                                                    "marginBottom": theme.spacing(5),
                                                    "marginLeft": theme.spacing(0.75),
                                                    "marginRight": theme.spacing(0.75),
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                                    "marginLeft": theme.spacing(2.375),
                                                    "marginRight": theme.spacing(2.375),
                                                },
                                            }),
                                        },
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "marginTop": theme.spacing(1.5),
                                                [theme.breakpoints.up('sm')]: {
                                                    "marginTop": theme.spacing(3.25),
                                                },
                                                "& .propertyresult-address": {
                                                    "fontWeight": theme.typography.fontWeightBold,
                                                    [theme.breakpoints.up('sm')]: {
                                                        "fontSize": theme.bodyLarge,
                                                        "lineHeight": theme.lineHeightBLarge,
                                                        "marginBottom": theme.spacing(0.25),
                                                    },
                                                },
                                                "& .propertyresult-rooms": {
                                                    // "paddingBottom": theme.spacing(1.25),
                                                },
                                            }),
                                        },
                                        "aside": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "display": "flex",
                                                "flexDirection": "row",
                                                "alignItems": "center",
                                                "justifyContent": "flex-end",
                                                "& .propertyresult-price": {
                                                    "marginRight": "auto",
                                                    [theme.breakpoints.up('sm')]: {
                                                        "fontSize": theme.bodyMedium,
                                                        "lineHeight": theme.lineHeightBMedium,
                                                    },
                                                },
                                            }),
                                        },
                                        // "price": {
                                        //     "in": "aside",
                                        //     "variant": "bodySmall",
                                        //     "component": "p",
                                        // },
                                        "address": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                            "component": "header",
                                        },
                                        "offer": {
                                            "in": "header",
                                            "variant": "bodyMedium",
                                        },
                                        "rooms": {
                                            "in": "header",
                                        },
                                        "bookviewing": {
                                            "in": "aside",
                                        },
                                        "save": {
                                            "in": "media",
                                        },
                                        "360tour": {
                                            "in": "media"
                                        },
                                        // "removeProperty": {
                                        //     "in": "aside",
                                        // },
                                        "media": {
                                            "sx": (theme) => ({
                                                "position": "relative",
                                                "overflow": "hidden",
                                                "& .propertyresult-link-image": {
                                                    "display": "block",
                                                    "&:after": {
                                                        "content": "''",
                                                        "position": "absolute",
                                                        "top": 0,
                                                        "left": 0,
                                                        "width": "100%",
                                                        "height": "100%",
                                                        "background": "rgba(0,0,0,0.2)",
                                                        "zIndex": "2",
                                                        "pointerEvents": "none",
                                                    },
                                                },
                                                "& .propertyresult-image": {
                                                    "display": "block",
                                                    "width": "100%",
                                                },
                                                "& .propertyresult-save": {
                                                    "position": "absolute",
                                                    "top": "10px",
                                                    "right": "10px",
                                                    "zIndex": "20",
                                                    "& svg": {
                                                        "color": "#fff",
                                                    },
                                                },
                                                "& .propertyresult-360tour": {
                                                    "position": "absolute",
                                                    "bottom": "15px",
                                                    "right": "15px",
                                                    "width": "auto",
                                                    "zIndex": 20,
                                                },
                                            })
                                        },
                                    },

                                    // "typography": {
                                    //     "h6": { "textTransform": "none" },
                                    //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                                    //     "subtitle2": { "textTransform": "none" },
                                    // },

                                    "addressFormat": {
                                        "separator": " , ",
                                        "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift()
                                        // "values": (p) => console.log(p)
                                    },
                                    "roomsFormat": {
                                        "separator": " ",
                                        "values": (p) => [p.bedrooms,p.receptions,p.bathrooms]
                                    },
                                    "linkFormat": {
                                        "separator": "-",
                                        "target":"_blank",
                                        // "values": (p) => propertyLink(p)
                                        // "values": (p) => console.log(p)
                                    },
                                    // "lettingsPricePrefix": "",
                                    "lettingsPriceSuffix": " PCM",
                                    // "salesPricePrefix": "",
                                    // "salesPriceSuffix": "",
                                },
                            },
                        },
                        "propertyAreaInfo": {},
                        "propertyDetailsRooms": {
                            "sx": (theme) => ({
                                "display": "flex",
                                "flexDirection": "row",
                                "& .roomsItem": {
                                    "display": "flex",
                                    "flexDirection": "row",
                                    "marginRight": theme.spacing(2.25),
                                    [theme.breakpoints.up('sm')]: {
                                        "marginRight": theme.spacing(3.25),
                                    },
                                    "& svg": {
                                        "marginRight": theme.spacing(1),
                                        "color": "#d1d2d3",
                                    },
                                    "& .roomsItem-text": {

                                    },
                                },
                            }),
                        },
                        "bookViewingBtn": {
                            "props": {
                                "btnLabelProps": "Book viewing online",
                                "btnProps": {
                                    "color": "primary",
                                    "variant": "text",
                                    "size": "small",
                                }
                            }
                        },
                    },
                },
                "propertyResultsMapPage": {
                },
                "carousel": { // options: src/components/MyAccount/carousel/defaults.js
                },
                "propertyAreaInfo": { // options: src/components/MyAccount/property/components/areaInfo/defaults.js
                },
                "propertyDetailsRooms": { // options: src/components/MyAccount/property/components/rooms/defaults.js
                },
                "locratingIframe": { // options: src/components/MyAccount/property/components/map/defaultsLocratingMap.js
                },
                "videoTour": { // options: src/components/MyAccount/property/components/videoTour/defaults.js
                },
                "virtualTour": { // options: src/components/MyAccount/property/components/virtualTour/defaults.js
                },
                "performanceTabs": { // options: src/components/MyAccount/pages/my-property/common/performance/defaults.js
                },
                "facebookPerformance": { // options: src/components/MyAccount/pages/my-property/common/performance/facebook/defaults.js
                },
                "performanceChart": { // options: src/components/MyAccount/pages/my-property/common/performance/performanceChart/defaults.js
                },
                "lineChart": { // options: src/components/MyAccount/charts/lineChart/defaults.js
                },
                "propertyDetailsPage": {
                    "subthemes": {
                        "propertyDetailsRooms": {
                            "props": {
                                "propertyBedsText": "Bedrooms",
                                "propertyBedsSingleText": "Bedroom",
                                "propertyBathsText": "Bathrooms",
                                "propertyBathsSingleText": "Bathroom",
                                "propertyRecText": "Receptions",
                                "propertyRecSingleText": "Reception",
                            },
                            "sx": (theme) => ({
                                "marginBottom": theme.spacing(2.25),
                                "display": "flex",
                                "flexDirection": "column",
                                [theme.breakpoints.up('sm')]: {
                                    "flexDirection": "row",
                                },
                                "& .roomsItem": {
                                    "display": "flex",
                                    "flexDirection": "row",
                                    [theme.breakpoints.up('sm')]: {
                                        "marginRight": theme.spacing(3.25),
                                    },
                                    "& svg": {
                                        "marginRight": theme.spacing(1),
                                    },
                                },
                            }),
                        },
                    }
                },
                "newsOverviewItem": {
                },
                "newsOverviewPage": {
                },
                "newsDetailsPage": { // options: src/components/MyAccount/blog/details/defaults.js
                },
                "404Page": { // options: src/components/MyAccount/404page/defaults.js
                },
                "myaccountFooter": { // options: src/components/MyAccount/footer/defaults.js
                    "props": {
                        "footerMenus": [
                            // {
                            //     "id": "link1",
                            //     "name": "Term of Use",
                            //     "url": termsSiteLink,
                            //     "target": "_blank",
                            // },
                            {
                                "id": "link2",
                                "name": "Privacy Policy",
                                "url": privacySiteLink,
                                "target": "_blank",
                            }
                        ],
                    }
                },
            // },
        // },
    },
}
